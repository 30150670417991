@font-face {
    font-family: "OwnersWide";
    src: url("../fonts/Owners/OwnersWide-Regular.woff2") format("woff2"),
        url("../fonts/Owners/OwnersWide-Regular.woff") format("woff");
}

@font-face {
    font-family: "OwnersWide";
    font-style: italic;
    src: url("../fonts/Owners/OwnersText-Italic.woff2") format("woff2"),
    url("../fonts/Owners/OwnersText-RegularItalic.woff") format("woff");
}

@font-face {
    font-family: "OwnersWide";
    font-weight: 700;
    src: url("../fonts/Owners/OwnersWide-Bold.woff2") format("woff2"),
    url("../fonts/Owners/OwnersWide-Bold.woff") format("woff");
}

/* @font-face {
    font-family: "OwnersWide";
    font-weight: 700;
    font-style: italic;
    src: url("../fonts/temp/Owners/Owners\ Wide\ Bold\ Italic.ttf") format("truetype");
} */

@font-face {
    font-family: "OwnersWide";
    font-weight: 500;
    src: url("../fonts/Owners/OwnersWide-Medium.woff2") format("woff2"),
    url("../fonts/Owners/OwnersWide-Medium.woff") format("woff");
}

/* @font-face {
    font-family: "OwnersWide";
    font-weight: 500;
    font-style: italic;
    src: url("../fonts/temp/Owners/Owners\ Wide\ Medium\ Italic.ttf") format("truetype");
} */

@font-face {
    font-family: "OwnersText";
    src: url("../fonts/Owners/OwnersText-Regular.woff2") format("woff2"),
        url("../fonts/Owners/OwnersText-Regular.woff") format("woff");
}

@font-face {
    font-family: "OwnersText";
    font-style: italic;
    src: url("../fonts/Owners/OwnersText-Italic.woff2") format("woff2"),
    url("../fonts/Owners/OwnersText-RegularItalic.woff") format("woff");
}