body {
    @apply antialiased;
}


.homeReelHeight {
    height: calc(100vh);
}

.fourToThree {
        width: 100%;
        padding-top: 75%; /* 1:1 Aspect Ratio */
        position: relative; /* If you want text inside of it */
}

.sixteenNine {
    width: 100%;
    padding-top: 56.25%; /* 1:1 Aspect Ratio */
    position: relative; /* If you want text inside of it */
}

.prose .image {
    @apply px-10 py-2;
}

.menuItems a {
    @apply flex-1 text-center;
}

.menuItemsMobile a li {
    @apply py-1;
}

.talentSection:nth-of-type(even) video, .talentSection:nth-of-type(even) img {
    @apply left-0;
}

.talentSection:nth-of-type(odd) video, .talentSection:nth-of-type(odd) img {
    @apply right-0;
}

.talentHeaders {
    @apply text-TpaWhite text-3xl md:text-6xl absolute z-20 h-full flex items-center w-3/5  text-left mx-6 md:mx-16 transform transition-transform duration-1000 delay-500;
}

.talentSection:nth-of-type(even) .talentHeaders {
    @apply absolute right-0 md:w-2/5;
}

.talentSection:nth-of-type(odd) .talentHeaders {
    @apply md:w-3/5;
}

.talentSection:nth-of-type(even):hover .talentHeaders {
    @apply md:-translate-x-10 delay-[0s];
}

.talentSection:nth-of-type(odd):hover .talentHeaders {
    @apply md:translate-x-10 delay-[0s];
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    @apply bg-TpaWhite w-10 rounded-none h-0.5;
}

.light-ham, .light-ham::before, .light-ham::after {
    @apply bg-TpaBlack;
}

.hamburger {
    @apply transform scale-75;
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
    @apply bg-TpaWhite w-10 rounded-none h-0.5;
}


.lightSocial {
    @apply hidden;
}

.footerLight .socialFooterSection .footerSocials a .lightSocial {
    @apply block;
}

.footerLight .socialFooterSection .footerSocials a .darkSocial {
    @apply hidden;
}


.staggeredItem {
    @apply col-span-5 md:col-span-3 py-10 !leading-[1.25em];
}

.staggeredItem:nth-of-type(even) {
    @apply md:col-start-3 tracking-tight;
}

.contactSections:nth-of-type(odd) {
    @apply md:flex-row-reverse;
}

.contactSections {
    @apply md:px-20 flex items-center flex-col md:flex-row;
}

.lazyImg {
    @apply opacity-0 transition-opacity duration-500;
}

.lazyloaded {
    @apply opacity-100;
}

.mainNavItem {
    @apply transition-colors duration-500;
}

.indexBlockInfo {
   @apply m-auto w-full px-2 text-TpaWhite font-owners font-medium text-base  pt-3 !leading-[1.05em] h-[2.7em]  line-clamp-2 tracking-normal;
}

.contactAddress br {
    @apply md:hidden
}

.contactAddress br:before {
    content: ', ';
}

/* purgecss start ignore */

:root {
    --plyr-color-main: #F6EFEF;
}

.plyr--hide-controls .video-info {
    opacity: 0;
}

.plyr--hide-controls .video-darken {
    opacity: 0;
}


.plyr__video-wrapper {
    width: 100vw ;
    max-height: 100% ;
    max-width: 177.5vh ;
}

.customPlayer {
    z-index: 100;
}


.twicVid {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }

  .twicPic {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  }
  /* .twicVid {
    opacity: 0;
    will-change: opacity;
    transition: opacity 1s linear, transform .75s ease-out;
  } */

  .twic-poster-done {
    opacity: 1;
  }

  .twic-done {
    opacity: 1;
  }

/* purgecss end ignore */