/* purgecss start ignore */

.vds-button[data-paused] .pause-icon,
.vds-button:not([data-paused]) .play-icon {
  display: none;
}

/* media-player[data-playing] media-poster {
    @apply opacity-0;
} */

/* .vidCover {
  @apply opacity-0 transition-opacity duration-[3s];
}

.vidCover[data-can-load-poster] {
  @apply duration-[3s] opacity-100;
} */

media-poster {
  @apply absolute top-0 left-0 z-50 w-full h-full object-contain transition-opacity duration-300
}

media-player[data-can-play] media-poster {
  @apply opacity-0;
}

media-controls-group svg {
    display: block;
    fill: white;
    height: 20px;
    pointer-events: none;
    width: 20px;
}

media-player[data-controls] #vidHeader, media-player[data-controls] #vidNextPrev, media-player[data-controls] #vidBack {
  @apply opacity-70
}

media-player[data-ended] #upNext {
  @apply opacity-100
}

media-player[data-ended] .rePoint {
  @apply pointer-events-auto
}

media-player {
  @apply bg-black;
}

media-player video {
  @apply transition-opacity duration-500 delay-300 object-contain w-full h-full;
}

media-player img {
  @apply w-full h-full object-contain object-center;
}

media-player[data-ended] video {
  @apply opacity-70
}

media-player[data-ended] #vidHeader {
  @apply opacity-0
}

media-player[data-ended] #upNext-button-bg {
  @apply delay-1000 duration-[7s] w-0;
}

.vidLoop video {
  @apply hidden;
}

.vidLoop[data-hover] video {
  @apply block;
}

/* purgecss end ignore */