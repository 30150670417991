@import "tailwindcss/base";

@import "./typography.css";
@import "./video/theme.css";
@import "./video/video.css";
@import "./video/customVid.css";
@import "tailwindcss/components";



@import "./hamburgers.css";

@import "./plyr2.css";


@import "./layout.css";



@import "tailwindcss/utilities";